// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 20px;
		line-height: 2;

		&::before {
			content: '';
			position: absolute;
			top: 0.75em;
			left: 0;
			width: 0.5em;
			height: 0.5em;
			border-radius: 100%;
			background: #38dad0;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;
		line-height: 2;

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $secondary;
			font-weight: 900;
		}
	}
}
