// btn
.btn {
	padding: 0.75rem 1.5rem;
	border-radius: 24px;
	font-weight: 900;

	&:hover {
		box-shadow: -2px 2px 7px rgba($black, 0.2);
	}

	// btn-primary
	&.btn-primary {
		&:hover {
			background-color: darken($primary, 25%);
			border-color: darken($primary, 25%);
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		color: $purple;

		&:hover {
			background-color: darken($secondary, 25%);
			border-color: darken($secondary, 25%);
			color: $white;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
			color: $white;
		}
	}
}

// card-btn
.card-btn {
	@extend .link-arrow;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
