// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: linear-gradient(180deg, rgba($black, 0.5) 0, rgba($black, 0) 100%);
	transition: 0.5s;

	&.sticky {
		background: $cream;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// site-switcher
	.site-switcher {
		.navbar-nav {
			&.language {
				.nav-item {
					.nav-link {
						img {
							@include media-breakpoint-up(lg) {
								width: 30px;
								height: 30px;
							}

							@include media-breakpoint-up(xl) {
								width: 40px;
								height: 40px;
							}
						}
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 168px;
		margin: 25px auto;
		transition: 0.5s;

		a {
			display: block;

			img {
				width: 100%;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				padding-bottom: 15px;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $purple;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $green;
						}
					}

					&.active {
						>.nav-link {
							color: $green;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 14px;

							.nav-link {
								color: $purple;

								&:hover {
									color: $green;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $green;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			flex-grow: 0;

			.navbar-nav {
				>.nav-item {
					margin-left: 15px;
					font-size: 16px;
					font-weight: 700;

					>.nav-link {
						padding-top: 0.75rem;
						padding-bottom: 0.75rem;
						color: $white;

						&:hover {
							color: $green;
						}
					}

					&.active {
						>.nav-link {
							color: $green;
						}
					}

					&:last-of-type {
						>.nav-link {
							padding: 0.75rem 1.5rem;
							border-radius: 24px;
							background-color: $purple;
							color: $white !important;
							font-weight: 900;

							&:hover {
								background-color: darken($purple, 25%);
								box-shadow: $shadow;
							}
						}

						&.active {
							>.nav-link {
								background-color: darken($purple, 25%);
								box-shadow: $shadow;
							}
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						background-color: $purple;
						border-color: $purple;

						&::before,
						&::after {
							border-bottom-color: $purple;
						}

						.nav-item {
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $white;

								&:hover {
									color: $green;
								}
							}

							&.active {
								.nav-link {
									color: $green;
								}
							}
						}
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 40px;
			height: 40px;
			padding: 0;
			border: none;
			background: $purple;
			border-radius: 100%;
			color: $white;
			font-size: 20px;
			transition: color 0.5s;

			i {
				line-height: 40px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}
}

&.sticky,
&.navbar-collapse-active {

	.header {
		background: $cream;

		.logo {
			margin-top: 15px;
			margin-bottom: 15px;

			img {
				filter: brightness(0) saturate(100%) invert(29%) sepia(14%) saturate(3870%) hue-rotate(206deg) brightness(87%) contrast(90%);
			}
		}

		// menu
		.menu {
			@include media-breakpoint-up(xl) {
				.navbar-nav {
					>.nav-item {
						>.nav-link {
							color: $purple;

							&:hover {
								color: $green;
							}
						}

						&.active {
							>.nav-link {
								color: $green;
							}
						}
					}
				}
			}
		}
	}
}

&.accommodation-book,
&.accommodation-search-book {
	.header {
		position: absolute;
	}
}
