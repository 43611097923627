// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home-intro-section
// =========================================================================
.home-intro-section {
	position: relative;
	font-size: 20px;
	text-align: center;

	&::after {
		content: url('/images/shape-green-flip.svg');
		z-index: 90;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
		background-color: $cream;
	}

	// collection
	.collection {
		margin: 6vh 0;
	}
}

// home-content-section
// =========================================================================
.home-content-section {
	margin: 6vh 0;
	font-size: 18px;

	.container-intro-text {
		.container-holder {
			max-width: 720px;

			h2 {
				color: $green;
			}
		}
	}

	// collection
	.collection {
		margin: 30px 0;

		&.grid {
			.grid-items {
				margin: 0 -10px -20px -10px;

				.item {
					@include make-col(6);

					@include media-breakpoint-up(lg) {
						@include make-col(3);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(3);
					}

					margin-bottom: 20px;
					padding-right: 10px;
					padding-left: 10px;
				}
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;
	font-size: 18px;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				margin-bottom: -40px;
				justify-content: flex-start;

				.item {
					margin-bottom: 40px
				}
			}
		}

		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
