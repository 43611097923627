// footer
.footer {
	margin-top: 10vh;

	// footer-outro
	.footer-outro {
		padding-bottom: 6vh;

		h3 {
			@include font-size($h2-font-size);
		}

		.column {
			&.one {
				padding-top: 15px;

				@include media-breakpoint-up(xl) {
					padding-top: 30px;
				}

				h3 {
					color: $green;
				}
			}

			&.two {
				@include media-breakpoint-up(lg) {
					form {
						>div {
							display: flex;
							flex-flow: row wrap;
							column-gap: 15px;

							.form-group {
								width: 100%;

								&:nth-child(2) {
									width: calc(50% - 15px);
								}

								&:nth-child(3) {
									width: 50%;
								}
							}
						}
					}
				}
			}
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		padding: 6vh 0;
		background-color: $purple;
		color: $white;
		font-weight: 700;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 1rem;
			color: $white;
		}

		a:not(.btn) {
			color: $white;
			text-decoration: none;

			&:hover {
				color: $green;
			}
		}

		.container-holder {
			margin-bottom: -30px;

			.column {
				margin-bottom: 30px;
			}
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-bottom: 0.25rem;
			}
		}

		.footer-contact-icons {
			ul:first-of-type {
				position: relative;
				margin-bottom: 2rem;
				padding-left: 25px;

				&::before {
					content: '\f3c5';
					position: absolute;
					top: 0;
					left: 0;
					font-family: $font-awesome;
					font-weight: 900;
				}
			}

			ul:last-of-type {
				li {
					a {
						position: relative;
						display: inline-block;
						padding-left: 25px;

						&::before {
							position: absolute;
							top: 0;
							left: 0;
							font-family: $font-awesome;
							font-weight: 900;
						}

						&[href^="mailto:"] {
							&::before {
								content: '\f0e0';
							}
						}

						&[href^="tel:"] {
							&::before {
								content: '\f879';
							}
						}
					}
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding-bottom: 30px;
		background-color: $purple;

		.footer-text {
			ul {
				display: flex;
				flex-direction: column;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-up(xl) {
					flex-direction: row;
				}

				li {
					@include media-breakpoint-up(xl) {
						margin-right: 15px;
					}

					color: $green;

					a:not(.btn) {
						color: $green;
						font-weight: 700;

						text-decoration: none;

						&:hover {
							color: $white;
						}
					}
				}
			}
		}
	}
}
