// mini-sab
.mini-sab {
	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: -60px 0 0 0;
			padding: 15px 0;
			border-radius: 8px;
			background: $purple;
			box-shadow: 2px 3px 12px rgba($black, 0.2);
			color: $white;

			@include media-breakpoint-up(lg) {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
}

&.home {
	.mini-sab {
		background-color: $green;
	}
}
