// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}

	@media (min-width: 1240px) {
		max-width: 1200px;
	}
}

// headings
h1 {
	color: $green;
}

&.home {
	h2 {
		margin-bottom: 2rem;
		font-size: 36px;

		@include media-breakpoint-up(xl) {
			font-size: 48px;
		}
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form[data-id] {
	padding: 15px;
	border-radius: 15px;
	box-shadow: -25px 25px 50px rgba($black, 0.2);
	background-color: $white;

	@include media-breakpoint-up(xl) {
		padding: 30px;
		border-radius: 30px;
	}

	.form-control {
		background-color: #fffae8;
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			border-radius: 8px;
			//box-shadow: 2px 3px 12px rgba($black, 0.2);
		}
	}
}

// main
&:not(.home) {
	.main {
		background: url('/images/bg-visual.svg') no-repeat center center;

		@media (max-width: 1400px) {
			background-size: 98% auto;
		}
	}
}

// default-card
.default-card {
	border: none;
	background-color: transparent;
	border-radius: 0;
	overflow: visible;

	.card-image {
		border-radius: 15px;
		transition: 0.5s;

		@include media-breakpoint-up(xl) {
			border-radius: 30px;
		}
	}

	.card-body {
		padding: 20px 0 0 0;

		.card-caption {
			.card-title {
				color: $purple;

				&:hover {
					color: $black;
				}
			}

			.card-subtitle {
				color: $green;
			}
		}
	}
}

// round-collection
.round-collection {
	&.grid {
		.grid-items {
			.item {
				@include media-breakpoint-up(xl) {
					flex: 0 !important;
					max-width: none !important;
				}

				.card {
					background: none;
					border: none;
					border-radius: 0;
					margin: 0 15px;

					.card-image {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 165px;
						height: 165px;
						margin: 0 auto 15px auto;
						border-radius: 100%;
						background-color: $white;
					}

					.card-body {
						padding: 0;
						text-align: center;

						.card-caption {
							.card-title {
								margin-bottom: 0;
								color: $purple;
								font-size: 24px;
							}

							.card-subtitle,
							.card-description {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

// portrait-collection
.portrait-collection {
	.card {
		border: none;
		border-radius: 15px;

		@include media-breakpoint-up(md) {
			border-radius: 30px;
		}

		.card-img-overlay {
			display: flex;
			flex-direction: column;

			@include media-breakpoint-up(md) {
				padding: 30px;
			}

			.card-caption {
				flex: 0;
				margin-top: auto;

				.card-title {
					margin-bottom: 0;
					@include font-size($h2-font-size);
					text-shadow: none;

					&:hover {
						color: $white;
					}
				}

				.card-subtitle,
				.card-description {
					display: none;
				}
			}

			.card-buttons {
				@include media-breakpoint-up(md) {
					height: 0;
					margin-top: 0;
					overflow: hidden;
					transition: 0.5s;
				}

				.card-btn {
					color: $green;

					&:hover {
						background: transparent;
						color: $white;
					}
				}
			}
		}

		&:hover {
			box-shadow: -25px 25px 50px rgba($black, 0.2);

			.card-buttons {
				margin-top: 15px;
				height: 24px;
			}
		}
	}
}

// link-arrow
.link-arrow {
	display: inline-flex;
	align-items: center;
	font-size: 16px;
	font-weight: 900;
	text-decoration: none;

	&::after {
		content: '\f178';
		margin-left: 10px;
		line-height: 1;
		font-family: $font-awesome;
	}
}

// modal
&.modal-open {
	overflow: visible;
	padding-right: 0 !important;

	.modal {
		display: flex !important;
		align-items: center;
		padding-right: 0 !important;
		right: 0;
		bottom: 0;

		.modal-content {
			border: none;
			border-radius: 8px;
			background: $purple;
			box-shadow: 2px 3px 12px rgba($black, 0.2);

			.modal-header {
				border-bottom: 0;
				padding-bottom: 0;

				.modal-title {
					color: $white;
					font-size: 20px;
				}

				.close {
					color: $white;
					opacity: 1;
				}
			}

			.modal-body {
				padding-top: 5px;
				color: rgba($white, 0.75);

				p:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}
