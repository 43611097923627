// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 70vh;
	min-height: 300px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 90vh;
	}

	@include media-breakpoint-up(xl) {
		min-height: 540px;
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	&::after {
		content: url('/images/shape-cream.svg');
		z-index: 90;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
	}

	// vimeo-video
	.vimeo-video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
			}
		}
	}

	// wysiwyg
	.wysiwyg {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-width: 780px;
		margin: auto;
		padding: 15px;
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
			text-align: center;
		}
	}

	// owl-carousel
	.owl-carousel {
		.item {
			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					max-width: 780px;

					.owl-caption {
						text-shadow: none;

						.owl-title {
							@include font-size($h1-font-size);
							line-height: 1;
						}
					}
				}
			}
		}
	}

	// owl-dots
	.owl-nav,
	.owl-dots {
		display: none;
	}
}

&.home {
	.eyecatcher {
		&::after {
			content: url('/images/shape-green.svg');
		}
	}
}
